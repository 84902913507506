import React from 'react';
import BaseModal from "./BaseModal";


interface Props {
    show: Boolean,
    closeModal():void;
    components: any[],
    connexions: any[]
}

interface State {
}

class DashboardModal extends React.Component<Props, State> {

    countServices = () => {
        let count = 0;
        this.props?.components?.forEach(component => {
            component?.required?.forEach(()=>count++);
            component?.provided?.forEach(()=>count++);
        })
        return count
    }

    render(){
        return (
            <BaseModal show={this.props.show.valueOf()} closeModal={this.props.closeModal} title={"Infos"}>
                {this.props?.components?.length} components running.<br />
                {this.countServices()} services presented.<br />
                {this.props?.connexions?.length} connexions registered.<br />
            </BaseModal>
        );
    }
};

export default DashboardModal;
