import React from 'react';
import BooleanConsumer from "./democomponents/interfaces/BooleanConsumer";


interface Props {
    target?: BooleanConsumer
}


class ToggleSwitch extends React.Component<Props>  {

    render(){
        const { target } = this.props;

        const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
            target?.consume?.(event.target.checked);
        };

        return (
            <div>
                <input type={"checkbox"} onChange={handleChange}/>
            </div>
        );
    }
}

export default ToggleSwitch;
