import React from 'react';
import {Menu, MenuItem, ProSidebar, SidebarContent, SidebarFooter, SidebarHeader, SubMenu} from "react-pro-sidebar";
import {
    FaChevronLeft,
    FaChevronRight,
    FaInfo,
    FaMicrochip,
    FaTachometerAlt,
    FaTrash, FaTrashAlt
} from "react-icons/all";
import ComponentEntries from "./democomponents/ComponentEntries";
import BaseModal from "./BaseModal";
import {OverlayTrigger, Tooltip} from "react-bootstrap";
import ComponentTooltip from "./ComponentTooltip";


interface Props {
    reset():void;
    resetConnexions():void;
    addComponent(type: string):void;
    showDashboardModal():void
}

interface State {
    collapsed: Boolean,
    infoModalShow: Boolean
}

class Aside extends React.Component<Props, State> {

    componentDidMount() {
        this.setState({
            collapsed: false,
            infoModalShow: false
        })
    }

    closeInfoModal = () => {
        this.setState({
            ...this.state,
            infoModalShow: false
        })
    }

    toggle() {
        this.setState({
            collapsed: !this.state?.collapsed
        })
    }

    render(){
        const {reset, resetConnexions, addComponent} = this.props;
        return (
            <ProSidebar collapsed={this.state?.collapsed.valueOf() || false}>
                <SidebarHeader>
                    <Menu iconShape="circle">
                        <MenuItem icon={this.state?.collapsed?<FaChevronRight />:<FaChevronLeft />} >
                            <a onClick={() => this.toggle()} title={this.state?.collapsed?"Expand Menu":""}>COLLAPSE</a>
                        </MenuItem>
                    </Menu>
                </SidebarHeader>
                <SidebarContent>
                    <Menu iconShape="circle">
                        <SubMenu
                            title={"ADD COMPONENT"}
                            icon={<FaMicrochip />}
                        >
                            {Array.from(ComponentEntries.keys()).map(key => (
                                <OverlayTrigger
                                    key={key}
                                    overlay={<Tooltip id={`${key}-tooltip`}><ComponentTooltip type={key} /></Tooltip>}
                                    placement={"right"}
                                >
                                    <MenuItem><a onClick={() => addComponent(key)}>{key}</a></MenuItem>
                                </OverlayTrigger>
                            ))}
                        </SubMenu>
                    </Menu>
                    <Menu iconShape="circle">
                        <MenuItem icon={<FaTrashAlt />}>
                            <a onClick={() => resetConnexions()}>RESET CONNEXIONS</a>
                        </MenuItem>
                        <MenuItem icon={<FaTrash />}>
                            <a onClick={() => reset()}>RESET COMPONENTS</a>
                        </MenuItem>
                    </Menu>
                </SidebarContent>
                <SidebarFooter>
                    <Menu iconShape="circle">
                        <MenuItem
                            icon={<FaTachometerAlt />}
                        >
                            <a onClick={this.props.showDashboardModal}>INFOS</a>
                        </MenuItem>
                        <MenuItem icon={<FaInfo />}><a onClick={() => this.setState({...this.state, infoModalShow: true})}>ABOUT</a></MenuItem>
                    </Menu>
                </SidebarFooter>
                <BaseModal show={this.state?.infoModalShow.valueOf() || false} closeModal={this.closeInfoModal} title={"About this project"}>TODOOOOOOOO</BaseModal>
            </ProSidebar>
        );
    }
};

export default Aside;
