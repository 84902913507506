import React from 'react';
import ComponentEntries from "./democomponents/ComponentEntries";
import InterfaceDescription from "./InterfaceDescription";


interface Props {
    type : string
}

interface State {}

class ComponentTooltip extends React.Component<Props, State> {

    getServiceDescription(service: any, prefix: string) {
        return(<React.Fragment>
            <br />
            <b>{prefix}</b> {service?.name}: {service?.interface?.name}<br />
            <InterfaceDescription inter={service?.interface} />
        </React.Fragment>)
    }

    render(){

        const { type } = this.props;

        const description = ComponentEntries.get(type)?.makeDescription("dummy");

        return (
            <React.Fragment>
                <h3>{type}</h3>
                {description?.description?<React.Fragment>${description?.description}<br /></React.Fragment>:""}
                {description?.required?.map((required: any) => this.getServiceDescription(required, "Requires"))}
                {description?.provided?.map((provided: any) => this.getServiceDescription(provided, "Provides"))}
            </React.Fragment>
        );
    }
};

export default ComponentTooltip;
