export default interface BooleanProducer {
    produce?():Boolean
}

export const descriptionBooleanProducer = {
    name: "BooleanProducer",
    id: "BooleanProducer",
    methods: [
        {
            name: "produce",
            return: "Boolean",
            parameters: []
        }
    ]
}
