import ComponentEntry from "../entities/ComponentEntry";
import React from "react";
import Lamp from "../Lamp";
import ToggleSwitch from "../ToggleSwitch";
import { v4 as uuid } from 'uuid';
import {descriptionBooleanConsumer} from "./interfaces/BooleanConsumer";
import {descriptionBooleanProducer} from "./interfaces/BooleanProducer";


const getProvidedOrUndefined = (requiredId:string, provided: Map<string, any>, connexions: {required: string; provided:string;}[]) => {
    const connexion = connexions.find(connexion => connexion.required === requiredId && provided.get(connexion.provided));
    if(connexion) {
        return provided.get(connexion.provided);
    }
    return undefined;
}

const map:Map<string, ComponentEntry> = new Map();

map.set(
    "Lamp",
    new class implements ComponentEntry {
        makeDescription(name: string): any {
            return({
                name: name,
                type: "Lamp",
                provided:[
                    {
                        name: "onoff",
                        id: uuid(),
                        interface: descriptionBooleanConsumer
                    },
                    {
                        name: "ison",
                        id: uuid(),
                        interface: descriptionBooleanProducer
                    }
                ],
                required:[]
            });
        }

        make(providedMap: Map<string, any>, cmpProvided: Map<string, string>, cmpRequired: Map<string, string>, connexions: {required: string; provided:string;}[]): any {

            return(
                <Lamp key={uuid()} onoff={providedMap.get(cmpProvided.get("onoff") as string)} ison={providedMap.get(cmpProvided.get("onoff") as string)} />
            );
        }
    }()
);

map.set(
    "ToggleSwitch",
    new class implements ComponentEntry {
        makeDescription(name: string): any {
            return({
                name: name,
                type: "ToggleSwitch",
                provided:[],
                required:[
                    {
                        name: "target",
                        id: uuid(),
                        interface: descriptionBooleanConsumer
                    }
                ]
            });
        }

        make(providedMap: Map<string, any>, cmpProvided: Map<string, string>, cmpRequired: Map<string, string>, connexions: {required: string; provided:string;}[]): any {
            return(
                <ToggleSwitch key={uuid()}  target={getProvidedOrUndefined(cmpRequired.get("target") as string, providedMap, connexions)} />
            );
        }
    }()
);

export default map;
