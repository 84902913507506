import React from 'react';
import {Modal} from "react-bootstrap";


interface Props {
    show: boolean;
    closeModal():void;
    title: string
}

interface State {
}

class BaseModal extends React.Component<Props, State> {

    render(){
        const {show, closeModal, title} = this.props;
        return (
            <Modal show={show} onHide={closeModal}>
                <Modal.Header closeButton>
                    <Modal.Title>{title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {this.props.children}
                </Modal.Body>
            </Modal>
        );
    }
};

export default BaseModal;
