import React from 'react';
import InterfaceDescription from "../InterfaceDescription";

interface Props {
    service: any
}

interface State {
}

class ServiceDescription extends React.Component<Props, State> {

    render(){

        const { service } = this.props;


        return (
            <div className={"ml-3 mt-3"}>
                <h4><code>{service?.name}</code></h4>
                {this.props.children}
                <h6><small>Id:</small> <code>{service?.id}</code></h6>
                <InterfaceDescription inter={service?.interface} />
            </div>
        );
    }
};

export default ServiceDescription;
