import React from 'react';
import {Form} from "react-bootstrap";


interface Props {
    serviceToConnect: any
    availableServices : any[]
    addConnexion(requiredId: string, providedId: string):void
}

interface State {
}

class NewConnexionInput extends React.Component<Props, State> {
    handleOnChange = (evt: any) => {
        //WORKS FOR FROMR EQUIRED TO PROVIDED ONLY
        this.props.addConnexion(this.props.serviceToConnect?.id, evt?.currentTarget?.value);
    }

    render(){

        const { availableServices } = this.props;

        return (
            <Form.Control as={"select"} onChange={this.handleOnChange}>
                <option value={"0"}>Connect to service</option>
                {availableServices.map((availableService: {service: any; component: any}) => <option value={availableService.service?.id}>
                    {availableService.service?.name} of {availableService.component?.name}
                </option>)}
            </Form.Control>
        );
    }
};

export default NewConnexionInput;
