import React from 'react';

interface Props {
    inter: any
}

class InterfaceDescription extends React.Component<Props> {

    getParametersString = (parameters: any[]) => {
        let description = "";
        parameters.forEach((parameter: any) => {
            description += `${parameter?.name}: ${parameter?.type},`
        })
        return description === ""?"":description.slice(0, -1);
    }

    getMethodDescription = (method: any) => {
        let description = `${method?.name}(${this.getParametersString(method?.parameters)}): ${method?.return}`;
        return(<span key={`${method?.name}-methoddesc`} className={"ml-4"}>{description}<br/></span>)
    }

    render(){

        const { inter } = this.props;

        return (
            <code>
                interface {inter?.id}{' {'}<br />{inter?.methods?.map(this.getMethodDescription)}{'}'}<br/>
            </code>
        );
    }
};

export default InterfaceDescription;
