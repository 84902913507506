import React from 'react';
import {Button, Card} from "react-bootstrap";
import { FaInfo, FaTimes} from "react-icons/all";


interface Props {
    deleteComponent(id: string): void;
    showComponentModal(component: any): void;
    component: any;
}

class ComponentTile extends React.Component<Props> {

    render(){
        const { deleteComponent, showComponentModal } = this.props;

        return (
                <Card bg={"dark"} className={"mb-4"}>
                    <Card.Header>
                        <Card.Title>
                            <h6>{this.props.component?.name}</h6>
                        </Card.Title>
                    </Card.Header>
                    <Card.Body>
                        <div className={"componentSpace"}>{this.props.children}</div>
                    </Card.Body>
                    <Card.Footer>
                        <Button className={"float-left"} variant={"outline-info"} onClick={() => showComponentModal(this.props.component) } title={"Component Details"}><FaInfo /></Button>
                        <Button className={"float-right"} onClick={() => deleteComponent(this.props.component?.id)} title={"Remove Component"} variant={"outline-warning"}><FaTimes /></Button>
                    </Card.Footer>
                </Card>
        );
    }
};

export default ComponentTile;
