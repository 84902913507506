export default interface BooleanConsumer {
    consume?(boolean : Boolean): void,
}

export const descriptionBooleanConsumer = {
    name: "BooleanConsumer",
    id: "BooleanConsumer",
    methods: [
        {
            name: "consume",
            return: "void",
            parameters: [
                {
                    name: "boolean",
                    type: "Boolean"
                }
            ]
        }
    ]
}
