import React from 'react';
import BooleanProducer from "./democomponents/interfaces/BooleanProducer";
import BooleanConsumer from "./democomponents/interfaces/BooleanConsumer";


interface Props {
    onoff : BooleanConsumer
    ison : BooleanProducer
}

interface State {
    on: Boolean
}

class Lamp extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);

        this.setState({
            on : false
        })
    }

    render(){

        const { onoff, ison } = this.props;

        onoff.consume = (boolean:Boolean) => {
            this.setState({
                on : boolean
            })
        }

        ison.produce = () => this.state?.on;

        return (
            <div style={{width:"100px",height:"100px",backgroundColor: this.state?.on?"yellow":"black" }}>

            </div>
        );
    }
};

export default Lamp;
