const endpoints = {
    getComponents: 'https://democomps.kevin-delcourt.net/api/get_components.php',
    getConnexions: 'https://democomps.kevin-delcourt.net/api/get_connexions.php',
    addComponent: 'https://democomps.kevin-delcourt.net/api/add_component.php',
    reset: 'https://democomps.kevin-delcourt.net/api/reset.php',
    resetConnexions: 'https://democomps.kevin-delcourt.net/api/reset_connexions.php',
    addConnexions: 'https://democomps.kevin-delcourt.net/api/add_connexions.php',
    deleteComponent: 'https://democomps.kevin-delcourt.net/api/delete_component.php',
};

export default endpoints;
