import React from 'react';
import BaseModal from "./BaseModal";
import {Alert} from "react-bootstrap";
import ServiceDescription from "./componentSettings/ServiceDescription";
import NewConnexionInput from "./componentSettings/NewConnexionInput";


interface Props {
    show: Boolean,
    closeModal():void,
    component: any,
    connexions: any[],
    components: any[],
    addConnexion(requiredId: string, providedId: string):void
}

interface State {}

class ComponentModal extends React.Component<Props, State> {

    findAvailableServicesForRequired = (required: any): any[] => {
        let found: any[] = [];
        this.props.components?.forEach((comp: any)=>{
            comp?.provided?.forEach((service: any) => {
                if(service?.interface?.id === required?.interface?.id) {
                    found.push({
                        component: comp,
                        service: service
                    })
                }
            })
        });
        return found;
    }

    getConnexionSelectForRequired = (required: any) => {
        const availableServices = this.findAvailableServicesForRequired(required);
        if(availableServices.length > 0) {
            return(
                <NewConnexionInput serviceToConnect={required} availableServices={availableServices} addConnexion={this.props?.addConnexion} />
            )
        }else{
            return "";
        }
    }

    findComponentWithServiceId = (id: string) => {
        let found: any = undefined;

        this.props.components?.forEach((component: any) => {
            component?.required?.forEach((service: any) => {
                if(service?.id === id) {
                    found = {
                        component: component,
                        service: service
                    }
                }
            })
            component?.provided?.forEach((service: any) => {
                if(service?.id === id) {
                    found = {
                        component: component,
                        service: service
                    }
                }
            })
        });
        return found;
    };

    getConnectedServiceOf = (id: string):any[] => {
        const connexions = this.props.connexions?.filter(connexion => connexion.required === id || connexion.provided === id);
        let found: any[] = [];
        connexions.forEach(connexion => {
            const oppositeComp = this.findComponentWithServiceId(id === connexion.required?connexion.provided:connexion.required);
            if(oppositeComp) {
                found.push(oppositeComp);
            }
        })
        return found;
    };

    getConnectedDescription = (connected: {service: any; component: any;}[]) => {
        if(connected.length === 1) {
            return(<Alert variant={"success"}>
                Connected to <code>{connected[0]?.service?.name}</code> of <code>{connected[0]?.component?.name}</code>
            </Alert>)
        }else if(connected.length > 1) {
            return(<Alert variant={"success"}>
                Connected to: <ul>{connected.map(element =>
                <li key={`${element?.service?.id}-cmapp`}>
                    <code>{element?.service?.name}</code> of <code>{element?.component?.name}</code>
                </li>
            )}</ul>
            </Alert>)
        }else {
            return "";
        }
    }

    getProvidedServiceDescription = (provided: any) => {
        return(<ServiceDescription service={provided}>
            {this.getConnectedDescription(this.getConnectedServiceOf(provided?.id))}
        </ServiceDescription>);
    };

    getRequiredServiceDescription = (required: any) => {
        const connected = this.getConnectedServiceOf(required?.id);
        return(<ServiceDescription service={required}>
            {connected.length > 0 ?
                this.getConnectedDescription(connected):
                this.getConnexionSelectForRequired(required)
            }
        </ServiceDescription>);
    }

    render(){

        const {component} = this.props;
        return (
            <BaseModal show={this.props.show?.valueOf() || false} closeModal={this.props.closeModal} title={component?.name}>
                <h4><small>Name:</small> <code>{component?.name}</code></h4>
                <h4><small>Type:</small> <code>{component?.type}</code></h4>
                <h4><small>Id:</small> <code>{component?.id}</code></h4>
                {component?.provided?.length > 0?<><br /><h5>Provided services</h5></>:""}
                {component?.provided?.map(this.getProvidedServiceDescription)}
                {component?.required?.length > 0?<><br /><h5>Required services</h5></>:""}
                {component?.required?.map(this.getRequiredServiceDescription)}
            </BaseModal>
        );
    };
}

export default ComponentModal;
