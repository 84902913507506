import React from 'react';
import ComponentEntries from "./democomponents/ComponentEntries";
import axios from 'axios';
import ApiEndpoints from "./utils/ApiEndpoints";
import Pusher from "pusher-js";
import ComponentTile from "./democomponents/ComponentTile";
import 'react-pro-sidebar/dist/css/styles.css';
import './app.css';
import Aside from "./Aside";
import {Col, Row} from "react-bootstrap";
import DashboardModal from "./DashboardModal";
import ComponentModal from "./ComponentModal";

Pusher.logToConsole = true;

const pusher = new Pusher('1cd2218202f350eeffef', {
    cluster: 'eu'
});

interface Props {}

interface State {
    open: Boolean,
    components: any[],
    connexions: any[],
    providedMap: Map<string, any>,
    showDashboardModal: Boolean,
    showComponentModal: Boolean,
    shownComponent: any
}

class App extends React.Component<Props, State>  {

    private getProvidedMap():Map<string, any> {
        const map: Map<string, any> = new Map();

        this.state?.components.forEach(element => {
            element.provided.forEach( (service:any) => {
                map.set(service.id, {});
            });
        });

        return map;
    }

    private renderComponents = () => {
        const map = this.getProvidedMap();
        return this.state?.components.map(element => {
            const cmpProvided:Map<string, string> = new Map();
            const cmpRequired:Map<string, string> = new Map();

            element.provided.forEach((service: { name: string; id: string; }) => {
                cmpProvided.set(service.name, service.id);
            });

            element.required.forEach((service: { name: string; id: string; }) => {
                cmpRequired.set(service.name, service.id);
            });

            return(
                <Col xs={12} sm={6} md={6} lg={4} xl={3} key={`key${element?.id}`}>
                    <ComponentTile deleteComponent={this.deleteComponent} component={element} showComponentModal={this.showComponentModal}>
                        {ComponentEntries.get(element.type)?.make(map, cmpProvided, cmpRequired, this.state.connexions)}
                    </ComponentTile>
                </Col>
            );
        });
    }

    showDashboardModal = () => {
        this.setState({...this.state, showDashboardModal: true})
    }

    closeDashboardModal = () => {
        this.setState({...this.state, showDashboardModal: false})
    }

    showComponentModal = (component: any) => {
        this.setState({...this.state, showComponentModal: true, shownComponent: component})
    }

    closeComponentModal = () => {
        this.setState({...this.state, showComponentModal: false})
    }

    addComponent = (type: string) => {
        let description = ComponentEntries.get(type)?.makeDescription(`${type} ${this.state.components.filter(component => component.type === type).length}`);

        axios.post(ApiEndpoints.addComponent, description)
        .then(function (response) {
            description.id = `${response.data}`;
        })

        this.setState({
            ...this.state,
            components: [...this.state.components, description]
        });
    }

    deleteComponent = (id: string) => {
        axios.post(ApiEndpoints.deleteComponent, {id: id})
        .then(function (response) {});

        this.setState({
            ...this.state,
            components: this.state.components.filter(component => component?.id !== id)
        });
    }

    reset = () => {
        axios.post(ApiEndpoints.reset)
            .then(function (response) {});

        this.setState({
            ...this.state,
            components: [],
            connexions: []
        })
    }

    addConnexion = (requiredId: string, providedId: string) => {
        axios.post(ApiEndpoints.addConnexions, [{required: requiredId, provided: providedId}])
            .then(function (response) {console.log(response)});

        this.setState({
            ...this.state,
            connexions: [...this.state.connexions, {required: requiredId, provided: providedId}]
        })
    }

    resetConnexions = () => {
        axios.post(ApiEndpoints.resetConnexions)
            .then(function (response) {});

        this.setState({
            ...this.state,
            connexions: []
        })
    }

    componentDidMount() {
        const channel = pusher.subscribe("democomps");

        this.setState({
            open: true,
            components: [],
            connexions: [],
            showDashboardModal: false,
            showComponentModal: false,
            shownComponent: {}
        });

        axios.get(ApiEndpoints.getComponents).then(response => {
            this.setState({
                ...this.state,
                components: response.data
            });
        })

        axios.get(ApiEndpoints.getConnexions).then(response => {
            this.setState({
                ...this.state,
                connexions: response.data
            });
        })

        channel.bind('reset', () => {
            this.setState({
                ...this.state,
                components: [],
                connexions: []
            })
        });

        channel.bind('connexions_reset', () => {
            this.setState({
                ...this.state,
                connexions: []
            })
        });

        channel.bind('connexions_added', (data:any) => {
            this.setState({
                ...this.state,
                connexions: [...this.state.connexions, ...data?.connexions?.filter((connexion: any) =>
                    !this.state.connexions.find(stConnexion =>
                        stConnexion?.provided === connexion?.provided
                    ) || this.state.connexions.length === 0
                )]
            })
        });

        channel.bind('component_added', (data:any) => {
            let ok = false;
            data?.component?.provided?.forEach( (provided:any) => {
                ok = ok || this.state.components.find(cmp => cmp?.provided?.find((cmpProvided: any)=> provided?.id === cmpProvided?.id))
            })

            data?.component?.required?.forEach( (required:any) => {
                ok = ok || this.state.components.find(cmp => cmp?.required?.find((cmpRequired: any)=> required?.id === cmpRequired?.id))
            })

            if(!ok) {
                this.setState({
                    ...this.state,
                    components: [...this.state.components, data?.component]
                });
            }
        });

        channel.bind('component_deleted', (data:any) => {
            this.setState({
                ...this.state,
                components: this.state.components.filter(component => component?.id !== data?.id)
            });
        });
    }

    render() {
        console.log(this.state);
        return (
            <div className={"app"}>
                <Aside addComponent={this.addComponent} reset={this.reset} resetConnexions={this.resetConnexions} showDashboardModal={this.showDashboardModal}/>
                <main>
                    <Row>
                        {this.renderComponents()}
                    </Row>
                </main>
                <DashboardModal show={this.state?.showDashboardModal || false} closeModal={this.closeDashboardModal} components={this.state?.components} connexions={this.state?.connexions} />
                <ComponentModal show={this.state?.showComponentModal || false} closeModal={this.closeComponentModal} component={this.state?.shownComponent} components={this.state?.components} connexions={this.state?.connexions} addConnexion={this.addConnexion}/>
            </div>
        );
    }
}

export default App;
